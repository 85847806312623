import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ICON from '@@/images/logos/logo-new.svg';

export default function Logo(props: SvgIconProps) {
  return (
    <SvgIcon
      component={ICON}
      viewBox="0 0 156 40"
      titleAccess="Cartinary Logo"
      {...props}
      sx={{
        display: 'block',
        width: {
          xs: 120,
          md: 200,
        },
        height: 'auto',
        // border: '2px solid black',
        ...(props?.sx || {}),
      }}
    />
  );
}
